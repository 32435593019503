import { useState, useEffect } from 'react';
import './TaskStatus.css';

function TaskStatus({ taskId }) {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL || 'https://api.automasterticket.com';

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetch(`${API_URL}/task-status/${taskId}`);
        
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setStatus(data);

        // Continue polling if task is not complete
        if (data.status === 'PENDING' || data.status === 'PROGRESS') {
          setTimeout(() => checkStatus(), 5000); // Poll every 5 seconds
        }
      } catch (err) {
        setError(err.message);
        console.error('Status check error:', err);
      }
    };

    if (taskId) {
      checkStatus();
    }

    // Cleanup function
    return () => {
      // Clear any pending timeouts if component unmounts
    };
  }, [taskId, API_URL]);

  if (error) {
    return <div className="task-status error">Error: {error}</div>;
  }

  if (!status) {
    return <div className="task-status loading">Checking status...</div>;
  }

  return (
    <div className="task-status">
      <h3>Task Status</h3>
      <p>Task ID: {status.task_id}</p>
      <p>Status: {status.status}</p>
      {status.result && (
        <div className="result">
          <h4>Results:</h4>
          <pre>{JSON.stringify(status.result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default TaskStatus; 
