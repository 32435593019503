import { useState } from 'react';
import ScraperForm from './components/ScraperForm';
import TaskStatus from './components/TaskStatus';
import './App.css';

function App() {
  const [taskId, setTaskId] = useState(null);
  
  const handleTaskCreated = (newTaskId) => {
    setTaskId(newTaskId);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Ticketmaster Scraper</h1>
      </header>
      <main>
        <ScraperForm onTaskCreated={handleTaskCreated} />
        {taskId && <TaskStatus taskId={taskId} />}
      </main>
    </div>
  );
}

export default App;
