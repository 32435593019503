import { useState } from 'react';
import './ScraperForm.css';

function ScraperForm({ onTaskCreated }) {
  const [url, setUrl] = useState('');
  const [sessionName, setSessionName] = useState('default_session');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL || 'https://api.automasterticket.com';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/start-scraping/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          session_name: sessionName
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || `Error: ${response.status}`);
      }

      const data = await response.json();
      onTaskCreated(data.task_id);
    } catch (err) {
      setError(err.message);
      console.error('Scraping error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="scraper-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="url">Ticketmaster URL:</label>
          <input
            type="url"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://www.ticketmaster.com/event/..."
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="sessionName">Session Name:</label>
          <input
            type="text"
            id="sessionName"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
          />
        </div>

        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Starting...' : 'Start Scraping'}
        </button>
      </form>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default ScraperForm; 
